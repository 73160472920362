@use 'sats-ui-lib/tokens/light';
@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/corner-radius';
@use 'sats-ui-lib/tokens/elevation';

.log-in {
  $breakpoint: 950px;

  background-color: light.$background-primary-default;
  color: light.$on-surface-primary-default;
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;

  @media (min-width: $breakpoint) {
    background-color: light.$fixed-background-primary-default;
    padding: spacing.$s;
    justify-content: center;
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    background-color: light.$background-primary-default;
    width: 100%;

    @media (min-width: $breakpoint) {
      flex-direction: row;
      border-radius: corner-radius.$s;
      margin: spacing.$m;
      max-width: 1000px;
    }
  }

  &__texts-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: spacing.$xl spacing.$l;
    max-width: 555px;
    width: 100%;

    @media (min-width: $breakpoint) {
      padding: spacing.$xl spacing.$xxl;
    }
  }

  &__logo {
    &--desktop {
      display: none;
      @media (min-width: $breakpoint) {
        display: block;
      }
    }

    &--mobile {
      display: block;
      @media (min-width: $breakpoint) {
        display: none;
      }
    }
  }

  &__logo,
  &__text,
  &__button {
    margin-bottom: spacing.$l;
  }

  &__button {
    width: 200px;
  }

  &__image {
    @media (min-width: $breakpoint) {
      height: 100%;
      width: 445px;
      overflow: hidden;
      border-radius: 0 corner-radius.$s corner-radius.$s 0;
    }

    &--desktop {
      @media (max-width: $breakpoint) {
        display: none;
      }
    }

    &--mobile {
      @media (min-width: $breakpoint) {
        display: none;
      }
    }
  }

  &__loading {
    display: flex;
    align-items: center;
    gap: spacing.$xs;
  }

  &__spinner {
    max-width: 24px;
    flex-grow: 0;
    margin-left: spacing.$xs;
  }
}
